import ApiService from './ApiService'
import { Know, KnowType, KnowStatus, KnowReviewHistory, KnowDocument, KnowCreateData, KnowTranscript } from 'interfaces/Know'
import store from '../redux/store'
import { Grade } from 'interfaces/Grade'
import { Subject } from 'interfaces/Subject'
import { SchoolType } from 'interfaces/SchoolType'
import { KnowComment } from 'interfaces/KnowComment'
import { Flashcard } from 'interfaces/Flashcard'
import { KnowSeoInfo } from 'interfaces/KnowSeoInfo'

const KnowService = {
    list(knowerUuid: string, pageNumber: number, cursor?: string) {
        return ApiService.get(`knowers/${knowerUuid}/knows?page=${pageNumber}${cursor ? `&cursor=${cursor}` : ''}`).then(
            (response) => {
                return {
                    nextCursor: response.data.nextCursor as string,
                    knows: response.data.content as Know[],
                }
            }
        )
    },

    listLatest() {
        return ApiService.get(`knows/latest`).then((response) => {
            return response.data.content as Know[]
        })
    },

    listOwn(pageNumber: number, searchQuery?: string, cursor?: string) {
        const knowerUuid = store().getState().knower?.uuid
        return ApiService.get(
            `knowers/${knowerUuid}/knows?page=${pageNumber}${
                searchQuery ? `&title=${encodeURIComponent(searchQuery)}` : ''
            }${cursor ? `&cursor=${cursor}` : ''}`
        ).then((response) => {
            return {
                nextCursor: response.data.nextCursor as string,
                knows: response.data.content as Know[],
            }
        })
    },

    countOwn() {
        const knowerUuid = store().getState().knower?.uuid
        return ApiService.get(`knowers/${knowerUuid}/knows?limit=1`).then((response) => {
            return response.data.totalElements as number
        })
    },

    get(knowUuid: string | number) {
        return ApiService.get(`knows/${knowUuid}`).then((response) => {
            return response.data as Know
        })
    },

    getSEOInfo(knowUuid: string | number) {
        return ApiService.get(`knows/${knowUuid}/seo`).then((response) => {
            return response.data as KnowSeoInfo
        })
    },

    getReviewHistory(knowUuid: string) {
        return ApiService.get(`knows/${knowUuid}/review-history?limit=1`).then((response) => {
            return response.data.content as KnowReviewHistory[]
        })
    },

    create(knowData: KnowCreateData) {
        return ApiService.post(`knows`, knowData).then((response) => {
            return response.data as Know
        })
    },

    update(
        knowUuid: string,
        title: string,
        description: string,
        knowType: KnowType,
        grades: Grade[],
        subjects: Subject[],
        schoolTypes: SchoolType[],
        examBoardUuids: string[]
    ) {
        return ApiService.patch(`knows/${knowUuid}`, {
            title,
            description,
            knowType,
            grades,
            subjects,
            schoolTypes,
            examBoardUuids,
        }).then((response) => {
            return response.data as Know
        })
    },

    delete(knowUuid: string) {
        return ApiService.delete(`knows/${knowUuid}`)
    },

    updateStatus(knowUuid: string, status: KnowStatus) {
        return ApiService.patch(`knows/${knowUuid}/status`, { status }).then((response) => {
            return response.data as Know
        })
    },

    getContents(knowUuid: string) {
        return ApiService.get(`knows/${knowUuid}/documents`).then((response) => {
            return response.data.content as KnowDocument[]
        })
    },

    createContent(knowUuid: string, fileName: string, title: string, orderNumber: number) {
        return ApiService.post(`knows/${knowUuid}/documents`, {
            fileName,
            title,
            orderNumber,
        }).then((response) => {
            return response.data as KnowDocument
        })
    },

    updateContent(knowUuid: string, contentUuid: string, orderNumber: number, title: string) {
        return ApiService.patch(`knows/${knowUuid}/documents/${contentUuid}`, {
            orderNumber,
            title,
        }).then((response) => {
            return response.data as KnowDocument
        })
    },

    deleteContent(knowUuid: string, uuid: string) {
        return ApiService.delete(`knows/${knowUuid}/documents/${uuid}`)
    },

    getTranscript(knowUuid: string) {
        return ApiService.get(`knows/${knowUuid}/transcript`).then((response) => {
            return response.data as KnowTranscript
        })
    },

    listSimilarKnowsViaAlgorithm(knowUuid: string, o?: { limit?: number }) {
        return ApiService.get(`knows/${knowUuid}/similar?limit=${o?.limit || 6}`).then((response) => {
            return response.data.content as Know[]
        })
    },

    countByCountry(countryIdOrCode: number | string, languageIdOrCode: number | string) {
        return ApiService.get(`/countries/${countryIdOrCode}/languages/${languageIdOrCode}/knows-count`).then((response) => {
            return response.data.knowCount as number
        })
    },

    getKnowComments(knowUuid: string) {
        return ApiService.get(`knows/${knowUuid}/comments`).then((response) => {
            return response.data.content as KnowComment[]
        })
    },

    getKnowCommentsResponses(commentUuid: string) {
        return ApiService.get(`knows/comments/${commentUuid}/responses`).then((response) => {
            return response.data.content as KnowComment[]
        })
    },

    getFlashcards(knowUuid: string) {
        return ApiService.get(`knows/${knowUuid}/flashcards?includeQuizFlashcards=true`).then((response) => {
            return response.data.content as Flashcard[]
        })
    },

    markAsViewed(knowUuid: string) {
        return ApiService.post(`knows/${knowUuid}/mark-viewed`, { knowUuid })
    },

    likeKnow(knowUuid: string) {
        return ApiService.post(`knows/${knowUuid}/likes`, { saveToLikedKnowsFolder: true })
    },

    removeKnowLike(knowUuid: string) {
        return ApiService.delete(`knows/${knowUuid}/likes`)
    },

    getGeneratedFlashcards(knowUuid: string) {
        return ApiService.get(`/knows/${knowUuid}/flashcards/generated?version=3`).then((response) => {
            return response.data.flashcards as Flashcard[]
        })
    },

    getSeoConversionCode(knowUuid: string, deviceUuid: string) {
        return ApiService.post(`/seo-conversion-codes`, { knowUuid, deviceUuid }).then((response) => {
            return response.data.code as string
        })
    },
}

export default KnowService
