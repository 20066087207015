import { useState, useEffect, RefObject } from 'react'

export default function useOnScreen(ref: RefObject<HTMLElement>, rootMargin = '0px') {
    const [isIntersecting, setIntersecting] = useState(false)
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting)
            },
            {
                rootMargin,
            }
        )

        ref.current && observer.observe(ref.current)

        return () => {
            ref.current && observer.unobserve(ref!.current)
        }
    }, [])
    return isIntersecting
}
