import { Flashcard } from 'interfaces/Flashcard'
import { Know } from 'interfaces/Know'
import { KnowSeoInfo } from 'interfaces/KnowSeoInfo'
import { ErrorTypes } from 'lib/constants/ErrorTypes'
import { getDomainByCountryCode, getLocalizedCountryOfOrigin } from 'lib/constants/country'
import { ParsedLocale } from 'lib/constants/localization'
import { ApiError } from 'services/ApiService'
import showdown from 'showdown'
import KnowService from 'services/KnowService'

export const fetchSimilarKnowsSSR = async (knowIdentifier: string) => {
    let similarKnows: Know[] | null = null
    try {
        similarKnows = await KnowService.listSimilarKnowsViaAlgorithm(knowIdentifier)
    } catch (error) {
        if (error instanceof ApiError) {
            throw error
        } else {
            throw error
        }
    }
    return similarKnows ?? null
}

// returns null if Know was Not Found
// returns undefined if Know couldn't be accessed
export const fetchKnowSEOInfoSSR = async (knowIdentifier: string) => {
    const converter = new showdown.Converter()
    converter.setOption('simpleLineBreaks', true)

    let seoInfo: KnowSeoInfo | null = null
    try {
        seoInfo = await KnowService.getSEOInfo(knowIdentifier)
    } catch (error) {
        if (error instanceof ApiError) {
            if (error.type === ErrorTypes.NotFound) {
                return null
            } else if (error.type === ErrorTypes.ForbiddenError) {
                return undefined
            } else {
                throw error
            }
        } else {
            throw error
        }
    }

    return {
        ...seoInfo,
        summary: seoInfo.summary ? converter.makeHtml(seoInfo.summary).replaceAll(/<\/?h1[^>]*>.*/g, '') : null,
        transcript: seoInfo.transcript
            ? seoInfo.isEnhancedTranscript
                ? converter.makeHtml(seoInfo.transcript).replaceAll(/<\/?h1[^>]*>.*/g, '')
                : seoInfo.transcript
            : null,
    }
}

export const fetchKnowFlashcardsSSR = async (knowIdentifier: string) => {
    let flashcards: Flashcard[] | null = null
    try {
        flashcards = await KnowService.getFlashcards(knowIdentifier)
    } catch (error) {
        if (error instanceof ApiError) {
            throw error
        } else {
            throw error
        }
    }
    return flashcards
}

export const isKnowNotIndexable = (know: Know) => !know.isSEOIndexed

export const shouldKnowBeRedirectionOccurBasedOnLanguage = (know: Know, parsedLocale: ParsedLocale) => {
    const domain = getDomainByCountryCode(know.knowerCountry.code)
    const knowLocalizationCountry = know.localizations?.length
        ? know.localizations?.map((localization) => localization.language.code)
        : []

    return (
        domain &&
        parsedLocale.countryCode !== getLocalizedCountryOfOrigin(know.knowerCountry.code) &&
        !knowLocalizationCountry.includes(parsedLocale.contentLanguageCode)
    )
}
