import { getReferrerScreen } from 'lib/formatting/url'
import { EventOptions, trackEvent } from 'lib/tracking/analytics-service'
import { useRouter } from 'next/router'
import { RefObject, useEffect, useState } from 'react'
import useOnScreen from './useOnScreen'

const ON_SCREEN_SECTION_OFFSET = '0px'

export default function useImpressionTracking(
    ref: RefObject<HTMLDivElement>,
    eventName: string,
    eventOptions?: EventOptions,
    condition?: boolean
) {
    const [wasEventSent, setWasEventSent] = useState(false)
    const router = useRouter()
    const referrerScreen = eventOptions?.referrerScreen ?? getReferrerScreen(router)
    const isOnScreen = useOnScreen(ref, ON_SCREEN_SECTION_OFFSET)

    useEffect(() => {
        if (!isOnScreen || wasEventSent || (condition !== undefined && !condition)) return

        trackEvent(eventName, { ...eventOptions, referrerScreen: referrerScreen })
        setWasEventSent(true)
    }, [isOnScreen, condition])
}
