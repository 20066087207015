import { getDomainByCountryCode } from 'lib/constants/country'
import { getDefaultMetaTitle } from 'lib/constants/language'
import { parseLocale } from 'lib/constants/localization'
import { getPathWithoutQuery } from 'lib/formatting/url'
import Head from 'next/head'
import { useRouter } from 'next/router'

interface Props {
    title: string | null
    description?: string
    noIndex?: boolean
    type?: 'article' | 'video' | 'website'
    imageUrl?: string
    canonical?: boolean
    profileUsername?: string
    author?: string
    authorUrl?: string
    reviews?: {
        ratingValue: number
        ratingCount: number
    }
}

const Meta = (props: Props) => {
    const router = useRouter()

    const title = props.title ? `${props.title} - Knowunity` : `Knowunity - ${getDefaultMetaTitle(router.locale)}`

    const parsedLocale = parseLocale(router.locale)
    const domain = getDomainByCountryCode(parsedLocale.countryCode)

    const urlOrigin = `https://${domain}${getPathWithoutQuery(router.asPath)}`

    const domainWithProtocol = `https://${domain}/`

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let schemaData: any = {
        '@context': 'https://schema.org',
        '@type': ['WebSite', 'SoftwareApplication'],
        name: 'Knowunity',
        operatingSystem: 'ANDROID',
        applicationCategory: 'EducationalApplication',
        brand: {
            '@type': 'Brand',
            name: 'Knowunity',
        },
        url: domainWithProtocol,
        image: props?.imageUrl ?? 'https://content-eu-central-1.knowunity.com/static/website-preview-image.png',
    }

    if (props?.reviews) {
        schemaData = {
            ...schemaData,
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: props.reviews.ratingValue,
                ratingCount: props.reviews.ratingCount,
            },
        }
    }

    return (
        <Head>
            <title>{title}</title>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schemaData),
                }}
            ></script>
            <meta property="og:site_name" content="Knowunity" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={urlOrigin} />
            <meta property="al:ios:app_name" content="Knowunity" />
            <meta property="al:ios:app_store_id" content="1484296272" />
            <meta property="al:ios:url" content={urlOrigin} />
            <meta property="al:android:url" content={urlOrigin} />
            <meta property="al:android:app_name" content="Knowunity" />
            {props.description ? (
                <>
                    <meta name="description" content={props.description} />
                    <meta name="og:description" content={props.description} />
                </>
            ) : null}
            <meta property="og:type" content={props.type ?? 'website'} />
            {props.noIndex ? <meta name="robots" content="noindex" /> : null}
            <meta
                property="og:image"
                content={props?.imageUrl ?? 'https://content-eu-central-1.knowunity.com/static/website-preview-image.png'}
            />
            {props.profileUsername ? <meta property="og:profile:username" content={props.profileUsername} /> : null}
            {props.canonical ? <link rel="canonical" href={urlOrigin} /> : null}
            {props.author ? <meta property="author" content={props.author} /> : null}
            {props.authorUrl ? <meta property="article:author" content={props.authorUrl} /> : null}
        </Head>
    )
}

export default Meta
