import { Know } from 'interfaces/Know'
import { FeedEventOptions, SearchEventOptions } from 'interfaces/TrackingSnippets'
import { AnalyticsEventKnowImpression } from 'lib/constants/AnalyticsEventType'
import { getReferrerScreen } from 'lib/formatting/url'
import useImpressionTracking from 'lib/hooks/useImpressionTracking'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import styled from 'styled-components'

interface Props {
    know: Know
    referrerScreen?: string
    feed?: FeedEventOptions
    search?: SearchEventOptions
}

const Container = styled.div`
    height: 0;
    width: 0;
`

const KnowImpressionTracking = ({ know, referrerScreen, feed, search }: Props) => {
    const knowRef = useRef<HTMLDivElement>(null)
    const router = useRouter()
    const fallbackReferrerScreen = getReferrerScreen(router)

    useImpressionTracking(knowRef, AnalyticsEventKnowImpression, {
        know,
        referrerScreen: referrerScreen || fallbackReferrerScreen,
        feed,
        search,
    })

    return <Container ref={knowRef} />
}

export default KnowImpressionTracking
